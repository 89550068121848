/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import styles from "./footer.module.scss";
import FooterCard from "./FooterCard";
import facebook from "../../../assests/facebook.svg";
import twitter from "../../../assests/homePage/twitter.svg";
import linkedin from "../../../assests/linkedin.svg";
import youtube from "../../../assests/youtube.svg";
import instagram from "../../../assests/Instagram.svg";
import logoOld from "../../../assests/logo.svg";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className={styles.footerContainer}>
      <Container>
        {/* <div className={styles.footerTopContent}>
          <div className={styles.footerFirstContent}>
            <h4>Business accounting you can trust</h4>
            <p>
              We’ll handle your business finances competently and efficiently.
              Our accountants <br /> and support teams hold a diverse range of
              qualifications and accreditations
              <br /> from professional bodies like the AAT and ACCA.
            </p>
          </div> */}
        {/* <div className={styles.footerSecondContent}>
            <FooterCard />
            <FooterCard />
            <FooterCard />
            <FooterCard />
            <FooterCard />
          </div> */}
        {/* </div> */}

        <div className={styles.footerThirdContent}>
          <div className={styles.footerMainContent}>
            <div className={styles.footerLeftContent}>
              <div className={styles.services}>
                <p>Who We Help </p>
                <ul>
                  <Link to="/whoWe/limitedCompanies">
                    {" "}
                    <li>Limited Companies</li>
                  </Link>
                  <Link to="/whoWe/soleTrade">
                    {" "}
                    <li>Sole Traders</li>
                  </Link>
                  <Link to="/whoWe/partnerShips">
                    {" "}
                    <li>Partnerships</li>
                  </Link>
                  <Link to="/whoWe/contractors">
                    {" "}
                    <li>Contractors</li>
                  </Link>
                  <Link to="/whoWe/freelancers">
                    {" "}
                    <li>Freelancers</li>
                  </Link>
                  <Link to="/whoWe/startups">
                    {" "}
                    <li>Startups</li>
                  </Link>
                </ul>
              </div>
              <div className={styles.services}>
                <p>Services</p>
                <ul>
                  <Link to="/services/accounts">
                    {" "}
                    <li>Accounts</li>
                  </Link>
                  <Link to="/services/tax-returns">
                    {" "}
                    <li>Tax Returns</li>
                  </Link>
                  <Link to="/services/vat-returns">
                    {" "}
                    <li>Vat Returns</li>
                  </Link>
                  <Link to="/services/bookKeeping">
                    {" "}
                    <li>Bookkeeping</li>
                  </Link>
                  <Link to="/services/payroll">
                    {" "}
                    <li>Payroll</li>
                  </Link>
                  <Link to="/services/self-assessment">
                    {" "}
                    <li>Self Assessment</li>
                  </Link>
                </ul>
              </div>
              <div className={styles.services}>
                <p>Resources</p>
                <ul>
                  <Link to="/resources/freebookKeeping-software">
                    {" "}
                    <li>
                      Free Bookkeeping
                      <br /> Software
                    </li>
                  </Link>
                  <Link to="/resources/guides-and-videos">
                    {" "}
                    <li>Guides and Videos</li>
                  </Link>
                  <Link to="/resources/blog">
                    {" "}
                    <li>Blog</li>
                  </Link>
                  {/* <Link to="/resources/instant-quote">
                    {" "}
                    <li>Instant Quote</li>
                  </Link> */}
                </ul>
              </div>
              <div className={styles.services}>
                <p>About Us</p>
                <ul>
                  {/* <Link to="/about-us/contact-us">
                    {" "}
                    <li>Meet the Team</li>
                  </Link> */}
                  <Link to="/about-us/contact-us">
                    {" "}
                    <li>Contact Us </li>
                  </Link>
                  {/* <Link to="/about-us/case-studies">
                    {" "}
                    <li>Case Studies</li>
                  </Link> */}
                </ul>
              </div>
            </div>
            <div className={styles.footerRightContent}>
              <span>Registered Address</span>
              <div className={styles.footerhead}>
                <p className={styles.address}>
                  37th Floor, One Canada Square <br />
                  Canary Wharf, London <br />
                  E14 5AA
                </p>
                <a href="tel:+0333 5775 33">
                  <p className="small">03335775 332</p>
                </a>
                <a href="tel:+0207 1831550">
                  {" "}
                  <p className="small">0207 1831550</p>
                </a>
                <a href="mailto:support@skytax.co.uk">
                  {" "}
                  <h6 className="small">support@skytax.co.uk</h6>
                </a>
              </div>

              <div className={`${styles.socialIcon} `}>
                <a
                  href="https://www.facebook.com/SkyTax.co.uk/"
                  target="_blank"
                >
                  <img src={facebook} alt="" />
                </a>
                <a target="_blank" href="https://twitter.com/SkyTax_Ltd">
                  <img src={twitter} alt="" />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/skytax-accountingltd"
                >
                  <img src={linkedin} alt="" />
                </a>
                <a target="_blank" href="https://www.youtube.com/@Skytax_01">
                  <img src={youtube} alt="" height={40} width={40} />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/skytaxuk?igsh=MWdmaDdsM3picGFsYg=="
                >
                  <img src={instagram} alt="" height={40} width={40} />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.footerCopyRight}>
            <div className={styles.footerCopyLeft}>
              <Link to="/">
                {" "}
                <img src={logoOld} alt="" />{" "}
              </Link>
            </div>
            <div className={styles.footerCopyRight1}>
              <span>Copyright © {year} SkyTax Ltd.</span>
            </div>
          </div>
        </div>
      </Container>
      {/* <div style={{ height: "30px" }}></div> */}
    </div>
  );
};

export default Footer;
