import { Formik } from "formik";
import { Form, Spinner } from "react-bootstrap";
import * as yup from "yup";
import styles from "./AddBlogForm.module.scss";
import "react-quill/dist/quill.snow.css"; // Import styles
import Select from "react-select";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { ToastContainer, toast } from "react-toastify";
import { BASE_IMAGE_URL } from "../../../services/constants";
// import "@ckeditor/ckeditor5-build-classic/build/translations/en.css";
// import "@ckeditor/ckeditor5-build-classic/build/ckeditor.css";
import { Editor } from "@tinymce/tinymce-react";
import { Global, css } from "@emotion/react"; // Update the import statement

interface IProps {
  toggleSidebar?: any;
  editableItem?: any;
  editView?: boolean;
  setInitinalValues?: any;
  initinalValues?: any;
}

interface Category {
  id: number;
  category_name: string;
  // Add other properties if needed
}

// const FILE_SIZE = 5 * 1024 * 1024;
// const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp']; // Add supported file formats as needed

const AddBlogsForm = ({
  editableItem,
  initinalValues,
  setInitinalValues,
  editView,
}: IProps) => {
  // const FILE_SIZE = 5 * 1024 * 1024; // 5MB (change to your desired maximum file size)
  // const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp']; // Add supported file formats as needed
  // const [imageError, setImageError] = useState('');

  const [blogContent, setBlogContent] = useState(
    editableItem ? editableItem.content : ""
  );
  const { id } = useParams();
  const [imagObjShow, setimageObjShow] = useState("");
  const [blogContentErro, setBlogContentErro] = useState(false);
  const [blogCatgoryErro, setBlogCatgoryErro] = useState(false);
  const [imagObj, setimageObj] = useState();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorImg, setErrorImg] = useState(false);
  // const [errorDis, setErrorDis] = useState(false);
  const navigate = useNavigate();
  const [tagOptions, setTagOptions] = useState<Category[]>([]);
  const [selectedTags, setSelectedTags] = useState<any>([]);

  const schema = yup.object().shape({
    title: yup.string().required("Title is required "),
    category_id: yup.string().required("Category is required "),
    // files: yup.string().required("Image is required field"),
    files: editView
      ? yup.string()
      : yup.string().required("Image is required field "),
    description: yup.string().required("Description is required "),
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setimageObj(file);
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) {
          setSelectedImage(e.target.result as string);
        }
      };
      setErrorImg(false);
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const [categoryListApi, { response: categoryListRes }] = useFetch(
    `/v1/category/list`,
    {
      method: "post",
    }
  );
  const [blogDetailsApi, { response: detailApiRes }] = useFetch(
    `/v1/blog/detail/${id}`,
    {
      method: "post",
    }
  );

  const handleTagChange = (selectedOptions) => {
    setBlogCatgoryErro(false);
    setSelectedTags(selectedOptions);
  };

  const [blogApi] = useFetch(
    editView && editableItem?.item?.id ? `/v1/blog/edit` : `/v1/blog/add`,
    // /v1/blog/edit/${editableItem.item.id}
    {
      method: editView ? "post" : "post",
    },
    "formdata"
  );

  const handleSubmit = async (values) => {
    if (selectedImage === null && !imagObj) {
      setErrorImg(true);
      return false;
    }
    let formdata = new FormData();
    formdata.append("title", values.title);
    formdata.append("description", values.description);
    formdata.append("files", imagObj || "");

    const categoryIds = selectedTags.map((tag) => tag.value);
    // formdata.append('category_id', selectedTags?.value);
    formdata.append("category_id", categoryIds.join(","));
    if (id) {
      formdata.append("blog_id", id);
    }
    try {
      setLoading(true);
      const response = await blogApi(formdata);
      setLoading(false);
      if (response.data && response.data.status === 1) {
        navigate("/admin/blog");
      } else {
        console.error("API Request Error:", response?.data?.message);
        if (response.data && response.data.status === 0) {
          toast.error(response.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Show a generic error message for other errors
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      console.error("API Request Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (detailApiRes && detailApiRes?.data) {
      const ids = detailApiRes.data.blog_categories.map(
        (item) => item.category_id
      );
      setInitinalValues({
        title: detailApiRes.data.title || "",
        description: detailApiRes.data.description || "",
        category_id: ids.join(","),
      });

      // Set the selected tags based on the blog details
      const selectedTags = detailApiRes.data.blog_categories.map(
        (category) => ({
          value: category.category_id,
          label: category.category_name,
        })
      );
      setSelectedTags(selectedTags);
      setBlogContent(detailApiRes?.data?.description);
      setimageObjShow(detailApiRes?.data?.image);
      setSelectedImage(detailApiRes?.data?.files);
    }
  }, [editableItem, detailApiRes]);

  useEffect(() => {
    // Update tagOptions when the API response is available
    if (categoryListRes && categoryListRes.data && categoryListRes.data.list) {
      const options = categoryListRes?.data?.list?.map(
        (category: Category) => ({
          value: category.id,
          label: category.category_name,
        })
      );
      setTagOptions(options);
    }
  }, [categoryListRes]);

  useEffect(() => {
    categoryListApi();
  }, [detailApiRes]);

  useEffect(() => {
    if (editView) {
      blogDetailsApi();
    }
  }, [editView]);

  useEffect(() => {
    const hideNotifications = document.querySelector(
      ".tox-notifications-container"
    ) as HTMLElement | null; // Use type assertion

    if (hideNotifications) {
      hideNotifications.style.display = "none";
    }
  }, []);

  // const onChange = (e) => {
  //   console.log(e.target.getContent());
  // };

  // console.log(blogContent, "blogContent");
  return (
    <div className={styles.blogBox}>
      <ToastContainer />
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={initinalValues}
        enableReinitialize={editView}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <>
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className={styles.formStep}>
                <h4> {editView ? "Edit Blog" : "Add Blog"} </h4>

                <div className={styles.twoFieldsWrap}>
                  <div className={styles.halfDivs}>
                    <Form.Group className={styles.singleField}>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                      {errors?.title && touched.title ? (
                        <p className="error">{errors?.title.toString()}</p>
                      ) : (
                        ""
                      )}
                    </Form.Group>

                    <Form.Group className={styles.singleField}>
                      <Form.Label>Categories</Form.Label>
                      <Select
                        options={tagOptions}
                        isMulti
                        // menuIsOpen
                        name="category_id"
                        value={selectedTags}
                        onChange={(e) => {
                          const ids = e.map((item) => item.value);
                          setFieldValue("category_id", ids.join(","));
                          handleTagChange(e);
                        }}
                      />
                      {errors?.category_id && touched.category_id ? (
                        <p className="error">
                          {" "}
                          {errors?.category_id.toString()}{" "}
                        </p>
                      ) : (
                        <></>
                      )}

                      {/* {blogCatgoryErro ? <>
                        <p className="error"> Catgory is requried</p></> : <>  </>} */}
                    </Form.Group>
                  </div>

                  <div className={`${styles.singleField} ${styles.upImages}`}>
                    <Form.Group>
                      <Form.Label>Feature Image</Form.Label>
                      <Form.Control
                        type="file"
                        name="files"
                        onChange={(e) => {
                          const inputElement = e.target as HTMLInputElement;
                          if (inputElement) {
                            const selectedFile = inputElement.files?.[0];
                            setFieldValue("files", selectedFile);
                          }
                          handleImageChange(e);
                        }}
                        // isInvalid={!!errors.images && touched.images ? true : false}
                      />
                      <div className={styles.uploadImg}>
                        Please Upload Feature Image
                      </div>

                      {/* <Form.Control.Feedback type="invalid">
                        {errors?.images ? (typeof errors?.images === "string" ? errors?.images : "") : ""}
                      </Form.Control.Feedback> */}
                      {errors.files && touched.files ? (
                        <p className="error">Image is requried</p>
                      ) : (
                        ""
                      )}
                    </Form.Group>

                    {selectedImage === undefined ? (
                      <>
                        {imagObjShow !== "" && (
                          <div className={styles.imgView}>
                            <img
                              src={`${BASE_IMAGE_URL}${imagObjShow}`}
                              alt="Preview"
                              style={{ maxWidth: "100px" }}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className={styles.imgView}>
                          {selectedImage && (
                            <img
                              src={selectedImage}
                              alt="Preview"
                              style={{ maxWidth: "100px" }}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  {/* <Form.Group className={styles.singleField}>
                    <Form.Label>Discription</Form.Label>
                    <ReactQuill
                      value={blogContent}
                      // onChange={handleBlogContentChange}
                      onChange={(content) => {
                        setBlogContent(content);
                        setFieldValue("description", content); // Update the "description" field in Formik
                        setBlogContentErro(false);
                      }}
                      modules={{
                        toolbar: {
                          container: [
                            ["bold", "italic", "underline", "strike"],
                            ["link", "image", "video"],
                            [
                              {
                                size: [
                                  "extra-small",
                                  "small",
                                  "medium",
                                  "large",
                                ],
                              },
                            ],
                            [{ list: "bullet" }, { list: "ordered" }], // Add bullets and numbers options
                            [{ blockquote: "" }, { "code-block": "" }], // Add blockquote and code-block options
                            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            [{ align: [] }],
                            [{ table: ["table"] }],
                            ["table"],
                            ["clean"],
                          ],
                        },

                        // imageResize: {
                        //   displaySize: true,
                        // },
                        // videoResize: {
                        //   displaySize: true,
                        // },
                      }} // Include the modules configuration
                    />
                    {errors?.description && touched.description && (
                      <p className="error">Discription is requried</p>
                    )}
                  </Form.Group> */}

                  {/* <Form.Group className={styles.singleField}>
                    <Form.Label>Discription</Form.Label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={blogContent}
                      onChange={(event, editor) => {
                        const newData = editor.getData();
                        setBlogContent(newData);
                        setFieldValue("description", newData);
                        setBlogContentErro(false);
                      }}
                      // config={{
                      //   // Add the required plugins for image handling
                      //   // plugins: [Image, ImageCaption, ImageStyle, ImageResize],
                      //   toolbar: {
                      //     items: [
                      //       "heading",
                      //       "|",
                      //       "bold",
                      //       "italic",
                      //       "link",
                      //       "bulletedList",
                      //       "numberedList",
                      //       "blockQuote",
                      //       "|",
                      //       "insertImage",
                      //       "imageStyle:full",
                      //       "imageStyle:side",
                      //       "imageCaption",
                      //       "imageResize",
                      //       "|",
                      //       "undo",
                      //       "redo",
                      //       "table",
                      //     ],
                      //   },
                      //   image: {
                      //     toolbar: [
                      //       "imageTextAlternative",
                      //       "imageStyle:full",
                      //       "imageStyle:side",
                      //     ],
                      //   },
                      // }}
                    />
                    {errors?.description && touched.description && (
                      <p className="error">Discription is requried</p>
                    )}
                  </Form.Group> */}

                  <Form.Group className={styles.singleField}>
                    <Form.Label>Discription</Form.Label>
                    <div>
                      <Global
                        styles={css`
                          .tox-notifications-container {
                            display: none !important;
                          }
                        `}
                      />
                      <Editor
                        apiKey="r33fht357sb48uzaif4s424d91smk1zo3s41jfq0gkx580ee"
                        initialValue=""
                        init={{
                          branding: false,
                          height: 400,
                          menubar: true,
                          plugins:
                            "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                          toolbar:
                            "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                          image_advtab: true,
                        }}
                        id="blogcontent"
                        // onChange={onChange}
                        value={blogContent}
                        // onChange={handleBlogContentChange}
                        onEditorChange={(newValue) => {
                          const newData = newValue;
                          setBlogContent(newData);
                          setFieldValue("description", newData);
                          setBlogContentErro(false);
                        }}

                        // onChange={(content) => {
                        //   setBlogContent(content);
                        //   setFieldValue("description", content); // Update the "description" field in Formik
                        //   setBlogContentErro(false);
                        // }}
                      />
                    </div>
                    {errors?.description && touched.description && (
                      <p className="error">Discription is requried</p>
                    )}
                  </Form.Group>

                  <button
                    className={`mb-0 ms-3 btn btn-primary ${styles.subBtn}`}
                    type="submit"
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only ms-1">
                          {editView ? "Update" : "Save"}
                        </span>
                      </>
                    ) : (
                      `${editView ? "Update" : "Save"}`
                    )}
                  </button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default AddBlogsForm;
