import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./blog.module.scss";
import CustomBtn from "../../../../common/theme/button";
import placeholderIcon from "../../../../assests/placeholderIcon.svg";
import HotPress from "../../../../components/webapp/hotPress";
import blog1 from "../../../../assests/blog1.svg";
import blog2 from "../../../../assests/blog2.svg";
import blog3 from "../../../../assests/blog3.svg";
import blog4 from "../../../../assests/blog4.svg";
import blog5 from "../../../../assests/blog5.svg";
import blog6 from "../../../../assests/blog6.svg";
import blog7 from "../../../../assests/blog7.svg";
import blog8 from "../../../../assests/blog8.svg";
import blog9 from "../../../../assests/blog9.svg";
import blog10 from "../../../../assests/blog10.svg";
import blog11 from "../../../../assests/blog11.svg";
import blog12 from "../../../../assests/blog12.svg";
import blog13 from "../../../../assests/blog13.svg";
import blog14 from "../../../../assests/blog14.svg";
import blog15 from "../../../../assests/blog15.svg";
import blog16 from "../../../../assests/blog16.svg";
import whiteCircle from "../../../../assests/whiteCrcle.svg";
import greenLine from "../../../../assests/greenLine.svg";
import circleLight from "../../../../assests/circleLight.svg";
import circleBlue from "../../../../assests/circleBlue.svg";
import whitecircle from "../../../../assests/whitecircle.png";
import lightBlueLine from "../../../../assests/lightBlueLine.svg";
import lineAndDot from "../../../../assests/lineAndDot.svg";
import GrayLine from "../../../../assests/GrayLine.svg";
import AboutUsFooter from "../../../../components/webapp/footer/aboutUsFooter";
import Header from "../../../../components/webapp/header";
import useFetch from "../../../../hooks/useFetch";
import PaginationCard from "../../../../components/admin/paginations";
import { useDispatch, useSelector } from "react-redux";
import { setCategoryId } from "../../../../redux/category";

const dataPress = [
  {
    title: "Simple",
    date: "18th July 2023",
    description:
      "Easy to use, efficient and accurate. hibooks has helped over 75,000 small business owners to become brilliant bookkeepers.",
    logo: blog1,
  },
  {
    title: "Tax efficiency reviews",
    date: "18th July 2023",
    description:
      "hibooks removes the stress of bookkeeping by streamlining every process, leaving you more time to focus on growing your business.",
    logo: blog2,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog3,
  },

  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog4,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog5,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog6,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog7,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog8,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog9,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog10,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog11,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog12,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog13,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog14,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog15,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: blog16,
  },
  // Add more data objects as needed
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 940,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 568,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Blog = () => {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [page, setPage] = useState<any>(null);
  const [filterId, setFilterId] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState<any>(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const [categoryLoading, setCategoryLoading] = useState(false);
  const LIMIT: number = 10;
  const categoryId = useSelector((state: any) => state.categoryId);
  const dispatch = useDispatch();
  const [categoryListApi, { response: apiResponseCategory }] = useFetch(
    `/v1/public-category/list`,
    {
      method: "post",
    }
  );
  console.log(apiResponseCategory, "apiResponseCategory");

  const allCategories = apiResponseCategory
    ? apiResponseCategory?.data?.list
    : [];

  useEffect(() => {
    if (page !== null) {
      setCategoryLoading(true);
      categoryListApi({
        start: 0,
        limit: -1,
        category_id: filterId,
      });
    }
    // eslint-disable-next-line
  }, [page]);

  const [blogListApi, { response: apiResponse }] = useFetch(
    `/v1/public-blog/list`,
    {
      method: "post",
    }
  );

  const allBlogs = apiResponse ? apiResponse?.data?.list : [];

  useEffect(() => {
    setTotalRecords(apiResponse?.data?.total_records);
    // eslint-disable-next-line
  }, [apiResponse]);

  useEffect(() => {
    setPage(0);
  }, []);

  useEffect(() => {
    if (page !== null) {
      setLoading(true);
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(() => {
        blogListApi({
          start: page,
          limit: LIMIT,
          category_id: filterId,
          search: searchTerm,
        });
      }, 1300);
      setDebounceTimeout(newTimeout);
    }
    // eslint-disable-next-line
  }, [page, filterId, searchTerm]);

  useEffect(() => {
    setLoading(false);
    setCategoryLoading(false);
    if (!apiResponse) {
      window.scrollTo(0, 0);
    }
  }, [apiResponse]);

  const catFilterSubmit = (e) => {
    if (!filterId.includes(e)) {
      setFilterId([...filterId, e]);
    } else {
      const categoryFilters = filterId.filter((id) => id !== e);
      setFilterId(categoryFilters);
    }
  };

  useEffect(() => {
    if (categoryId?.data !== -1) {
      catFilterSubmit(categoryId?.data);
      dispatch(setCategoryId(-1));
    }
  }, [categoryId]);
  const showAllCategories = () => {
    setFilterId([]);
  };

  const isMobileViews = window.innerWidth <= 768;

  return (
    <>
      <Header />
      <Container>
        <div className={styles.guidesAndVideosPage}>
          <Row className="align-items-center">
            <Col xs={12}>
              <div className={styles.greenLine}>
                <img src={greenLine} alt="" />
              </div>
              <div className={styles.lineAndDot}>
                <img src={lineAndDot} alt="" />
              </div>
              <div className={styles.check}>
                <span>News, views and expert advice</span>
                <h1>Blog</h1>
                <p>
                  Welcome to our blog. We hope it helps to keep you informed and
                  gives you ideas and strategies to help your business develop
                  and grow.{" "}
                </p>

                <form>
                  <div className={styles.input_wrapper}>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);

                        // Clear the existing timeout
                        if (debounceTimeout) {
                          clearTimeout(debounceTimeout);
                        }

                        // Set a new timeout for 300ms
                        const newTimeout = setTimeout(() => {
                          // Call your search function here
                          // Example: yourSearchFunction(e.target.value);
                        }, 1300);

                        // Save the timeout ID for clearing if needed
                        setDebounceTimeout(newTimeout);
                      }}
                    />
                    <img src={placeholderIcon} alt="" />
                  </div>
                </form>

                <div className={styles.buttons}>
                  {/* <CustomBtn>Call to action</CustomBtn>
                  <CustomBtn variant="outline-primary">
                    Call to action
                  </CustomBtn> */}
                </div>
              </div>

              <div className={`${styles.circlelight} ${styles.grow}`}>
                <img src={circleLight} alt="" />
              </div>
              <div
                className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
              >
                <img src={whiteCircle} alt="" />
              </div>
              <div className={styles.circleblue}>
                <img src={circleBlue} alt="" />
              </div>
              <div className={styles.circleWhite}>
                <img src={whitecircle} alt="" />
              </div>
              <div className={styles.lightBlueLine}>
                <img src={lightBlueLine} alt="" />
              </div>
            </Col>
          </Row>
          <div className={styles.GrayLine}>
            <img src={GrayLine} alt="" />
          </div>
        </div>
      </Container>
      <div className={styles.whyChooseAccount}>
        <h2>Browse our articles</h2>
        <p>
          Click on a category to filter articles according to the topics that
          interest you.
        </p>
        <Container>
          <div className={styles.accountButtons}>
            <Row>
              <Col xs={12}>
                <div
                  className={styles.aah}
                  // style={{ width: "max-content", margin: "auto" }}
                >
                  {categoryLoading ? (
                    <div className="text-center">
                      <p className={styles.centerLoader}>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </p>
                    </div>
                  ) : (
                    <>
                      {isMobileViews ? (
                        <>
                          {allCategories.length > 1 ? (
                            <div
                              className={`sliderCustomBlog ${styles.buttonGap} hoverBtn`}
                            >
                              <Slider {...settings}>
                                <Button
                                  className={`${styles.customBtnCategory} ${
                                    styles.customBtnCategoryActive
                                  } ${
                                    filterId.length === 0
                                      ? styles.activeCategory
                                      : ""
                                  }`}
                                  variant="outColor-primary"
                                  onClick={showAllCategories}
                                  type="button"
                                >
                                  Show All Categories
                                </Button>

                                {allCategories.map((category) => (
                                  <Button
                                    style={{
                                      boxShadow: "none",
                                      transition: "none",
                                      transform: "none",
                                    }}
                                    className={`${styles.customBtnCategory} ${
                                      filterId.includes(category.id)
                                        ? styles.activeCategory
                                        : ""
                                    }`}
                                    variant="outColor-primary"
                                    key={category.id}
                                    onClick={() => {
                                      catFilterSubmit(category.id);
                                    }}
                                    type="button"
                                  >
                                    {category.category_name}
                                  </Button>
                                ))}
                              </Slider>
                            </div>
                          ) : (
                            <div
                              className={`sliderCustomBlog justify-content-center ${styles.buttonGap}`}
                            >
                              <Button
                                className={`${styles.customBtnCategory} ${
                                  styles.customBtnCategoryActive
                                } ${
                                  filterId.length === 0
                                    ? styles.activeCategory
                                    : ""
                                }`}
                                variant="outColor-primary"
                                onClick={showAllCategories}
                                type="button"
                              >
                                Show All Categories
                              </Button>

                              {allCategories.map((category) => (
                                <Button
                                  className={`${styles.customBtnCategory} ${
                                    filterId.includes(category.id)
                                      ? styles.activeCategory
                                      : ""
                                  }`}
                                  variant="outColor-primary"
                                  key={category.id}
                                  onClick={() => {
                                    catFilterSubmit(category.id);
                                  }}
                                  type="button"
                                >
                                  {category.category_name}
                                </Button>
                              ))}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {allCategories.length > 5 ? (
                            <div
                              className={`sliderCustomBlog ${styles.buttonGap}`}
                            >
                              <Slider {...settings}>
                                <Button
                                  className={`${styles.customBtnCategory} ${
                                    styles.customBtnCategoryActive
                                  } ${
                                    filterId.length === 0
                                      ? styles.activeCategory
                                      : ""
                                  }`}
                                  variant="outColor-primary"
                                  onClick={showAllCategories}
                                  type="button"
                                >
                                  Show All Categories
                                </Button>

                                {allCategories.map((category) => (
                                  <Button
                                    className={`${styles.customBtnCategory} ${
                                      filterId.includes(category.id)
                                        ? styles.activeCategory
                                        : ""
                                    }`}
                                    variant="outColor-primary"
                                    key={category.id}
                                    onClick={() => {
                                      catFilterSubmit(category.id);
                                    }}
                                    type="button"
                                  >
                                    {category.category_name}
                                  </Button>
                                ))}
                              </Slider>
                            </div>
                          ) : (
                            <div
                              className={`sliderCustomBlog justify-content-center ${styles.buttonGap}`}
                            >
                              <Button
                                className={`${styles.customBtnCategory} ${
                                  styles.customBtnCategoryActive
                                } ${
                                  filterId.length === 0
                                    ? styles.activeCategory
                                    : ""
                                }`}
                                variant="outColor-primary"
                                onClick={showAllCategories}
                                type="button"
                              >
                                Show All Categories
                              </Button>

                              {allCategories.map((category) => (
                                <Button
                                  className={`${styles.customBtnCategory} ${
                                    filterId.includes(category.id)
                                      ? styles.activeCategory
                                      : ""
                                  }`}
                                  variant="outColor-primary"
                                  key={category.id}
                                  onClick={() => {
                                    catFilterSubmit(category.id);
                                  }}
                                  type="button"
                                >
                                  {category.category_name}
                                </Button>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className={styles.h}>
        {loading ? (
          <div className="text-center">
            <p className={styles.centerLoader}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </p>
          </div>
        ) : (
          <>
            <HotPress
              allBlogs={allBlogs}
              dataPress={dataPress}
              heading=""
              subHeading=""
            />

            <div className={styles.blogPages}>
              <Container>
                {totalRecords > LIMIT && (
                  <div className="d-flex justify-content-center mt-3">
                    <PaginationCard
                      itemPerPage={LIMIT}
                      totalItems={totalRecords}
                      paginate={setPage}
                      currentPage={page ?? 0}
                    />
                  </div>
                )}
              </Container>
            </div>
          </>
        )}
      </div>
      <AboutUsFooter />
    </>
  );
};

export default Blog;
